@use '../../scss/mixin';
@use '../../scss/global';

body {
   background-color: global.$backgroundColor;
   margin: 0;
   padding: 0;
}

.headerBlock {
   margin: 0;
   padding: 0;
   height: global.$headerHeight;
   background-color: global.$backgroundColor;
   grid-row: 1/2;
   grid-column: 1/2;
}

.layout {
   box-sizing: border-box;
   padding: 0;
   margin: 0 auto;
   display: grid;
   min-height: 100vh;
   grid-template: global.$headerHeight 1fr max-content / 1fr;

   @media screen and (min-width: 200px) and (max-width: 499px) {
      grid-template: global.$headerHeight 1fr / 1fr;
   }

}

.main {
   margin: 0;
   padding: 0;
   display: grid;
   grid-template: 1fr / 1fr;
}

.menu-btn {
   width: 30px;
   height: 30px;
   position: relative;
   z-index:2;
   overflow: hidden;
}
.menu-btn span {
   width: 30px;
   height: 2px;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   background-color: #222222;
   transition: all 0.5s;
}
.menu-btn span:nth-of-type(2) {
   top: calc(50% - 5px);
}
.menu-btn span:nth-of-type(3) {
   top: calc(50% + 5px);
}

.hamburgerMenu {
   padding: 0;
   margin: 0;
   position: fixed;
   top: 50px;
   left: 0;
   width: 100%;
   height: 100%;
   border-right: solid 1px global.$appMainColor;
   background: global.$backgroundColor;
   transform: translateX(-100%);
   transition: transform 0.5s;
   z-index: 3000;
   display: grid;
   grid-template: 40px max-content 1fr / 1fr max-content 1fr;

   &_active {
      transform: translateX(0);
   }

   &__list {
      padding: 0;
      margin: 0;
      grid-row: 2/3;
      grid-column: 2/3;
   }

   &__link {
      list-style: none;
      border-bottom: solid 1px global.$appMainColor;
   }

   &__link:first-child {
      list-style: none;
      border-top: solid 1px global.$appMainColor;
   }

}


