@use "../../scss/mixin";

.settings {
   margin: 0;
   padding: 0;
   display: grid;
   grid-template: max-content auto / max-content 1fr;

   &__main {
      list-style: none;
      grid-row: 2/3;
      grid-column: 1/3;
      padding: 0 10px 0 10px;
      margin: 0;
   }
}

