@use '../../scss/mixin';
@use '../../scss/global';

.footer {
   box-sizing: border-box;
   margin: 0;
   padding: 5px;
   grid-area: footer;
   grid-row: 3/4;
   grid-column: 1/2;
   border-top: solid 1px global.$borderColor;

   @media screen and (min-width: 200px) and (max-width: 499px) {
     display: none;
   }

   &__text {
      margin: 0;
      padding: 0;
      align-self: center;
   }

}