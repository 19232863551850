@use "../../scss/mixin";
@use "../../scss/global";

.userSettings {
   grid-row: 2/3;
   grid-column: 1/2;
   padding: 20px 20px 20px 50px;
   color: global.$mainTextColor;
   font-family: global.$mainFontFamily;
   font-weight: 400;
   font-size: 20px;

   &__title {
      @include mixin.titleUserPageInfo
   }

   &__main {
      grid-row: 1/2;
      display: grid;
      grid-template: max-content / 250px 1fr;
      padding: 0;
      margin: 0;
      gap: 50px;
   }

}

.buttonArea {
   margin: 0;
   padding: 0;
   display: grid;
   grid-template: max-content / 150px 150px 1fr;
   gap: 20px;
}

.button {
   margin: 20px 20px 0 0;
   padding: 10px;
   width: 100%;
   outline: none;
   font-weight: 500;
   font-size: 16px;
   line-height: 18px;
   text-align: center;
   color: global.$buttonEnabledColor;
   background-color: global.$backgroundColor;
   border: 1px solid global.$appMainColor;
   border-radius: 3px;
   cursor: pointer;
   box-shadow: 2px 2px 2px global.$appMainColor;

   &:hover {
      background-color: global.$appMainColor;
      color: global.$buttonTextColor;
      border: 1px solid global.$appMainColor;
   }
}

