@use "../../scss/global";
@use "../../scss/mixin";

.triangleBottom {
   width: 0;
   height: 0;
   border-left: 5px solid transparent;
   border-right: 5px solid transparent;
   border-top: 5px solid global.$backgroundColor;
}

.header {
   box-sizing: border-box;
   margin: 0;
   padding: 5px 20px;
   grid-area: header;
   grid-row: 1/2;
   grid-column: 2/3;
   display: grid;
   grid-template: auto / max-content max-content 1fr max-content;
   gap: 20px;
   height: global.$headerHeight;
   background-color: global.$appMainColor;
   align-items: center;
   position: fixed; /* Сделайте его липким/фиксированным */
   top: 0; /* Оставайтесь на вершине */
   width: 100%; /* Полная ширина */
   transition: top 0.3s; /* Эффект перехода при скольжении вниз (и вверх) */
   z-index: 100;


   @media screen and (max-width: 1023px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
   }


   &__hamburger {
      grid-column: 1/2;
      width: 40px;
      height: auto;
      cursor: pointer;
      display: none;

      &_hide {
         display: none;
      }

      @media screen and (max-width: 1023px) {
         display: block;
      }
   }

   &__logo {
      grid-column: 2/3;
      width: 70px;
      height: auto;

      @media screen and (max-width: 1023px) {
         grid-column: 1/2;
      }
   }

   &__userBlock {
      grid-column: 4/5;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 1023px) {
         grid-column: 1/2;
      }

      &_nonactive {
         display: none;
      }
   }

   &__userPicBlock {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      color: global.$mainColor;
      pointer-events: auto;

      &_notClick {
         pointer-events: none;
      }
   }

   &__userPic {
      margin: 0 0 0 10px;
      padding: 0;
      grid-column: 1/2;
      width: auto;
      height: 30px;
      cursor: pointer;
   }

   &__triangle {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid global.$backgroundColor;
   }

   &__userText {
      margin: 0;
      padding: 0;
      font-size: 16px;
      color: global.$backgroundColor;

      @media screen and (min-width: 200px) and (max-width: 399px) {
         display: none;
      }

   }

   &__exitpic {
      margin: 0 0 0 10px;
      padding: 0;
      grid-column: 3/4;
      width: auto;
      height: 30px;
   }

}
