//Ширина экрана
$win-mobile: 486px;
$win-tablet: 924px;
$win-laptop: 1024px;
$win-large: 1440px;

// Высоты
$footerHeight: 40px;
$mainHeight: calc(100vh - 90px);

// Цвета
$appMainColor: #492e20; // Основной цвет приложения
$mainTextColor: #201708; // Цвет основного текста
$mainAccentColor: #0a2079; // Цвет акцентного текста
$backgroundCardColor: #e7e1db; // Цвет бэкграунда карточки

$mainColor: #937153; // Главный цвет
$textColorLink: #215be3; // Цвет ссылок
$textColorMenuSidebar: $appMainColor; // Цвет текста меню сайдбара

$backgroundColor: #faf7f2; // Цвет заднего фона
$mainMenuColor: #4d3d26; // Цвет текста меню
$borderColor: $mainColor; // Цвет бордюра

// Хедер
$headerColor: #5d4822; // Цвет текста хедера
$headerHeight: 50px; // Высота хедера

// Error
$errorTextColor: #d90f2f; // Цвет сообщения об ошибках

// Кнопки
$buttonEnabledColor: #704510; // Цвет активной кнопки
$buttonDisabledColor: #faf7f2; // Цвет неактивной кнопки
$buttonTextColor: #faf7f2; // Цвет текста кнопки

// PopupDialogue
$backgroundPopupColor: #f8f8f8; // Цвет заднего фона PopupDialogue

//Типографика
$mainFontFamily: Verona, Arial, sans-serif;
$navigateFontFamily: "Cormorant Infant", Arial, sans-serif;

.cardLink {
   margin: 0;
   padding: 0;
   font-size: 20px;
   color: $textColorLink;

}