@use "../../scss/mixin";
@use "../../scss/global";

.filmInfo {
   grid-row: 2/3;
   grid-column: 1/2;
   padding: 0 20px 20px 20px;
   color: global.$mainTextColor;
   font-family: global.$mainFontFamily;
   font-weight: 400;
   font-size: 20px;

   &__mainInfo {
      grid-row: 1/2;
      display: grid;
      grid-template: max-content / max-content 1fr;
      padding: 10px 0;
      margin: 10px 0 0 0;
      gap: 50px;

      @media screen and (min-width: 800px) and (max-width: 1920px) {
         gap: calc(20px + (50 - 20) * ((100vw - 800px) / (1920 - 800)));
      }

      @media screen and (min-width: 200px) and (max-width: 799px) {
         gap: calc(5px + (15 - 5) * ((100vw - 200px) / (799 - 200)));
         grid-template: max-content max-content / 1fr;
         display: flex;
         flex-direction: column;

      }

   }

   &__image {
      grid-row: 1/2;
      grid-column: 1/2;
      padding: 0;
      margin: auto;
      width: 250px;
      height: auto;
      border-radius: 10px;

      @media screen and (min-width: 200px) and (max-width: 1920px) {
         width: calc(180px + (250 - 180) * ((100vw - 200px) / (1920 - 200)));
      }

      @media screen and (min-width: 200px) and (max-width: 799px) {
         width: 100%;
         object-fit: contain;
         object-position: center center;
      }

      &_big {
         display: block;
         @media screen and (max-width: 799px) {
            display: none;
         }
      }

      &_min {
         display: none;
         @media screen and (max-width: 799px) {
            display: block;
         }

      }
   }

      &__content {
         grid-row: 1/2;
         grid-column: 2/3;
         display: grid;
         grid-template: max-content / 1fr;
         align-self: flex-start;
         gap: 5px;
         margin: 0;
         padding: 0;

      }

      &__description {
         grid-row: 2/3;
         grid-column: 1/2;
         margin: 20px 0 0 0;
         padding: 0;
         @include mixin.textCardInfo;

         &_hide {
            display: none;
         }
      }

   &__descriptionBlock {
      padding: 0;
      margin: 20px 0 0 0;
      text-decoration: none;
      font-family: 'Cormorant Infant', 'Arial', sans-serif;
      font-size: 30px;
      font-weight: 700;
      color: global.$appMainColor;
      cursor: pointer;

      @media screen and (min-width: 200px) and (max-width: 1260px) {
         margin: 0 auto;
         padding: 10px 0;
      }
   }

   &__triangleRight {
      display: inline-block;
      margin: 0;
      padding: 0;
      font-size: 14px;
      align-items: center;
   }

   &__triangleDown {
      display: inline-block;
      margin: 0;
      padding: 0;
      font-size: 20px;
      align-items: center;
   }

   &__triangleNone {
      display: none;
   }

   }