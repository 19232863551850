@use './global';

@mixin border-main {
   border: solid 1px rgba(0, 0, 0, .3);
}

@mixin colorLink {
   margin: 0;
   padding: 10px;
   text-align: left;
   font-size: 20px;
   font-weight: normal;
   color: global.$textColorLink;
}

@mixin menuPageLink {
   margin: 0;
   color: global.$textColorLink;
   font-family: global.$navigateFontFamily;
   font-weight: 700;
   font-size: 24px;
   cursor: pointer;
}

//отображение "хлебных крошек"
@mixin breadCrumbs {
   margin: 0;
   padding: 0;
   //padding: 15px 10px;
   color: global.$appMainColor;
   font-family: global.$navigateFontFamily;
   font-weight: 700;
   font-size: 30px;
   cursor: auto;
   pointer-events: none;
   //border-bottom: solid 1px global.$appMainColor;


   @media screen and (min-width: 200px) and (max-width: 1920px) {
      font-size: calc(16px + (30 - 16) * ((100vw - 200px) / (1920 - 200)));
   }

}

//текст для сайдбар меню
@mixin menuText {
   color: global.$textColorMenuSidebar;
   font-family: global.$navigateFontFamily;
   font-weight: 700;
   font-size: 24px;
   cursor: pointer;

   @media screen and (max-width: global.$win-laptop) {
      font-size: 20px;
   }

}

@mixin titleCard {
   color: global.$mainAccentColor;
   font-family: global.$navigateFontFamily;
   font-weight: 700;
   font-size: 20px;
}

@mixin textCard {
   color: global.$appMainColor;
   font-family: global.$navigateFontFamily;
   font-weight: 700;
   font-size: 18px;
}

@mixin textCardBrief {
   color: global.$appMainColor;
   font-family: global.$navigateFontFamily;
   font-weight: 700;
   font-size: 18px;

   @media screen and (max-width: 1359px) {
      font-size: 18px;
   }

   @media screen and (min-width: 1360px) {
      font-size: calc(17px + (18 - 17) * ((100vw - 1460px) / (1460 - 1360)));
   }

   @media screen and (min-width: 1461px) {
      font-size: 18px;
   }
}

@mixin textDialogueMessage {
   color: global.$appMainColor;
   font-family: global.$navigateFontFamily;
   font-weight: 600;
   font-size: 24px;
}

@mixin textCardInfo {
   color: global.$appMainColor;
   font-family: global.$navigateFontFamily;
   font-weight: 600;
   font-size: 24px;
}

@mixin titleCardInfo {
   color: global.$mainAccentColor;
   font-family: global.$navigateFontFamily;
   font-weight: 700;
   font-size: 26px;
}

@mixin textDescriptionCardInfo {
   color: global.$appMainColor;
   font-family: global.$navigateFontFamily;
   font-weight: 600;
   font-size: 24px;
}

@mixin titleDescriptionCardInfo {
   color: global.$appMainColor;
   font-family: global.$navigateFontFamily;
   font-weight: 700;
   font-size: 28px;
   text-decoration: underline;
}

@mixin titleImage {
   color: global.$backgroundColor;
   font-family: global.$navigateFontFamily;
   font-weight: 700;
   font-size: 60px;

   @media screen and (min-width: 800px) and (max-width: 1920px) {
      font-size: calc(30px + (60 - 30) * ((100vw - 800px) / (1920 - 800)));
   }

   @media screen and (min-width: 200px) and (max-width: 799px) {
      font-size: calc(16px + (30 - 16) * ((100vw - 200px) / (799 - 200)));
   }

}

@mixin textImage {
   color: global.$backgroundColor;
   font-family: global.$navigateFontFamily;
   font-weight: 500;
   font-size: 35px;
   line-height: 40px;
   letter-spacing: 2px;

   @media screen and (min-width: 1024px) and (max-width: 1920px) {
      font-size: calc(20px + (35 - 20) * ((100vw - 1024px) / (1920 - 1024)));
      line-height: calc(25px + (40 - 25) * ((100vw - 1024px) / (1920 - 1024)));
      letter-spacing: calc(1 + (2 - 1) * ((100vw - 1024px) / (1920 - 1024)));
   }

   @media screen and (min-width: 700px) and (max-width: 1023px) {
      font-size: calc(18px + (28 - 18) * ((100vw - 700px) / (1023 - 700)));
      line-height: calc(20px + (30 - 20) * ((100vw - 700px) / (1023 - 700)));
      letter-spacing: calc(.5 + (2 - 1) * ((100vw - 700px) / (1023 - 700)));
   }

   @media screen and (min-width: 200px) and (max-width: 699px) {
      font-size: calc(8px + (25 - 8) * ((100vw - 200px) / (699 - 200)));
      line-height: calc(8px + (30 - 8) * ((100vw - 200px) / (699 - 200)));
      letter-spacing: calc(0 + (.5) * ((100vw - 200px) / (699 - 200)));
   }
}

@mixin titleCardInfoLink {
   color: global.$textColorLink;
   font-family: global.$navigateFontFamily;
   font-weight: 700;
   font-size: 26px;
}

@mixin button {
   outline: none;
   font-weight: 500;
   font-size: 16px;
   line-height: 18px;
   text-align: center;
   background-color: global.$backgroundCardColor;
   color: global.$appMainColor;
   border: 1px solid global.$appMainColor;
   border-radius: 3px;
   cursor: pointer;
}

@mixin card {
   width: 400px;
   height: 180px;
   position: relative;
   box-sizing: border-box;
   padding: 0;
   margin: 0;
   cursor: pointer;
   perspective: 1000px;
   border-radius: 5px;

   @media screen and (min-width: 1400px) and (max-width: 1460px) {
      width: calc(380px + (400 - 380) * ((100vw - 1400px) / (1460 - 1400)));
   }

   @media screen and (min-width: 1350px) and (max-width: 1399px) {
      width: calc(360px + (380 - 360) * ((100vw - 1350px) / (1399 - 1350)));
      height: 190px;
   }

   @media screen and (min-width: 1300px) and (max-width: 1349px) {
      width: calc(350px + (360 - 350) * ((100vw - 1300px) / (1349 - 1300)));
      height: 200px;
   }

   @media screen and (min-width: 1024px) and (max-width: 1299px) {
      width: calc(395px + (400 - 395) * ((100vw - 1024px) / (1299 - 1024)));
      height: 180px;
   }

   @media screen and (min-width: 900px) and (max-width: 1023px) {
      width: 400px;
      height: 180px;
   }

   @media screen and (min-width: 750px) and (max-width: 899px) {
      width: 350px;
      height: 200px;
   }

   @media screen and (min-width: 451px) and (max-width: 749px) {
      width: 400px;
      height: 180px;
   }

   @media screen and (min-width: 400px) and (max-width: 450px) {
      width: 350px;
      height: 200px;
   }

   @media screen and (min-width: 200px) and (max-width: 399px) {
      width: calc(180px + (360 - 180) * ((100vw - 200px) / (399 - 200)));
      height: calc(300px - (300 - 250) * ((100vw - 200px) / (399 - 200)));
   }

}

@mixin titleUserPageInfo {
   padding: 0 0 20px 0;
   margin: 0;
   color: global.$appMainColor;
   font-family: global.$navigateFontFamily;
   font-size: 36px;
   font-weight: 700;
}

