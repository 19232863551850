@use "../../../scss/global";
@use "../../../scss/mixin";

.select {
   margin: 10px 0 0 0;
   padding: 0;
   display: grid;
   grid-template: max-content / 1fr;

   &__head {
      position: relative;
      grid-column: 1/2;
      padding: 0;
      margin: 0;
      display: grid;
      grid-template: max-content / max-content 1fr max-content;
      border: 1px solid black;
      //cursor: pointer
   }

   &__img {
      display: block;
      box-sizing: border-box;
      height: 29px;
      margin: 0;
      padding: 5px;
      background-color: global.$appMainColor;
      border-radius: 3px;
      align-items: center;
      cursor: pointer;

      &_clip {
         grid-column: 1/2;
      }

      &_upload {
         grid-column: 3/4;
      }

      &_none {
         display: none;
      }
   }

   &__text {
      grid-column: 2/3;
      margin: 0;
      padding: 0;
      align-self: center;
      @include mixin.textCard
   }

   &__input {
      grid-column: 2/3;
      margin: 0;
      padding: 0 10px;
      align-self: center;
      @include mixin.textCard;
      outline: none;
      border: none;
      cursor: pointer;
   }

   &__list {
      grid-row: 2/3;
      grid-column: 1/3;
      padding: 0;
      margin: 0;
      display: grid;
      grid-template: auto / 1fr;
      max-height: 150px;
      overflow: auto;
      background-color: #ffffff;
      border-bottom: 1px solid black;
      border-left: 1px solid black;
      border-right: 1px solid black;
      transition: height .2s ease;

      &_none {
         border-bottom: none;
         height: 0;
         transition: height 0.2s ease;
      }
   }

   &__item {
      padding: 2px 10px;
      margin: 0;
      border-bottom: 1px solid rgba(111, 114, 115, 0.5);
      cursor: pointer;

      &_check {
         background-color: rgba(224, 229, 231, 1);
      }

      &:last-of-type {
         border-bottom: none;
      }

   }

}

.inputBlock {
   position: relative;
   grid-row: 1/2;
   grid-column: 1/2;
   margin: 0;
   padding: 0;
   background-color: global.$backgroundColor;
   border: 1px solid global.$appMainColor;
   display: grid;
   grid-template: max-content / max-content 1fr;
   gap: 10px;
   cursor: pointer;

   &__input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      display: block;
      width: 0;
      height: 0;
   }

   &__imgButton {
      display: block;
      box-sizing: border-box;
      height: 29px;
      margin: 0 0 0 5px;
      padding: 5px;
      background-color: global.$appMainColor;
      border-radius: 3px;
      align-items: center;
      cursor: pointer;
   }

}




