@use '../../scss/mixin';
@use '../../scss/global';

.cardList {
   grid-row: 1/2;
   margin: 0 15px;
   padding: 0;
   display: grid;
   grid-template: max-content 1fr / 1fr;

   &__listHead {
      margin: 0;
      padding: 15px 10px;
   }

   &__cramb {
      margin: 0;
      padding: 15px 10px;
      display: grid;
      grid-template: max-content / 1fr max-content;
      border-bottom: solid 1px global.$appMainColor;
   }

   &__buttonAdd {
      display: block;
      grid-row: 1/2;
      grid-column: 3/4;
      margin: 0;
      padding: 0;
   }

   &__list {
      grid-row: 2/3;
      grid-column: 1/2;
      margin: 0;
      padding: 20px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 20px;

      @media screen and (max-width: 450px) {
         padding: 15px 5px 5px 5px;
      }

   }

   &__main {
      @include mixin.menuPageLink;
   }

   &__title {
      grid-row: 1/2;
      padding: 10px;
      @include mixin.menuPageLink;
   }

   &__noVisible {
      display: none;
   }

   &__addNewCard {
      margin: 0;
      padding: 0;
      display: block;
   }

   &__addCard {
      @include mixin.card;

      background-color: global.$backgroundCardColor;
      box-sizing: border-box;
      padding: 30px;
      margin: 0;
      border: solid 1px global.$appMainColor;
      border-radius: 3px;
      cursor: pointer;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, .5);
   }

   &__imgNewCard {
      display: block;
      margin: 0 auto;
      height: 100%;
      padding: 0;
   }

   &__noVisible {
      display: none;
   }
}
