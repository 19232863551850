@use "../../../scss/global";

.blockArray {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template: auto / 1fr;
  gap: 10px;
}

.inputBlock {
  margin: 0;
  padding: 0;
  background-color: global.$backgroundColor;
  display: flex;
  flex-direction: row;

  &__input {
    width: 100%;
    height: 25px;
    padding: 0 5px;
    align-items: center;
    font-family: 'Cormorant Infant', 'Arial', sans-serif;
    color: global.$appMainColor;
    font-size: 22px;
    font-weight: 500;
  }

  &__imgButton {
    display: block;
    box-sizing: border-box;
    height: 29px;
    margin: 0 0 0 5px;
    padding: 5px;
    background-color: global.$appMainColor;
    border-radius: 3px;
    align-items: center;
    cursor: pointer;

    &_none {
      display: none;
    }
  }

  &__imgCheck {
    box-sizing: border-box;
    height: 29px;
    margin: 0 0 0 3px;
    padding: 5px;
    background-color: global.$appMainColor;
    border-radius: 3px;
    cursor: pointer;
  }

  &__text {
    grid-row: 1/2;
    grid-column: 1/2;
    margin: 0;
    padding: 0;
    align-self: center;
    background-color: global.$backgroundColor;
    color: global.$appMainColor;
    font-size: 16px;
  }
}
