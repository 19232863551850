@use "../../scss/mixin";
@use "../../scss/global";

.addCard {
  grid-row: 2/3;
  grid-column: 1/2;
  padding: 20px;
  color: global.$mainTextColor;
  font-family: global.$mainFontFamily;
  font-weight: 400;
  font-size: 20px;

  &__avatarBlock {
    pointer-events: auto;

    &_none {
      pointer-events: none;
    }
  }

  &__title {
    @include mixin.titleUserPageInfo;

    @media screen and (min-width: 200px) and (max-width: 1260px) {
      text-align: center;
    }
  }

  &__main {
    grid-row: 1/2;
    display: grid;
    grid-template: max-content / 250px 1fr;
    padding: 0;
    margin: 0;
    column-gap: 50px;
    row-gap: 0;

    @media screen and (min-width: 200px) and (max-width: 1260px) {
      grid-template: max-content 1fr / 1fr;
      gap: 10px;
    }
  }

  &__avatar {
    grid-row: 1/2;
    grid-column: 1/2;

    @media screen and (min-width: 200px) and (max-width: 1260px) {
      grid-row: 1/2;
      grid-column: 1/2;
    }
  }

  &__content {
    grid-row: 1/2;
    grid-column: 2/3;
    margin: 0;
    padding: 0 10px 10px 10px;

    @media screen and (min-width: 200px) and (max-width: 1260px) {
      grid-row: 2/3;
      grid-column: 1/2;
      padding: 0;
    }
  }

  &__description {
    grid-row: 2/3;
    grid-column: 1/3;
    margin: 0;
    padding: 0 10px 10px 10px;

    @media screen and (min-width: 200px) and (max-width: 1260px) {
      grid-row: 3/4;
      grid-column: 1/2;
      padding: 0;
    }

    &_none {
      pointer-events: none;
    }
  }

  &__list {
    grid-row: 1/2;
    grid-column: 2/3;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template: auto /  1fr;
  }

  &_itemsLocation {
    margin: 10px 0 0 0;
    list-style: none;
    display: grid;
    grid-template: max-content max-content/ 1fr max-content;
    border-bottom: 1px solid #492e20;

    &__locationInputArea {
      margin: 0;
      grid-row: 1/2;
    }

    &__locationCheckArea {
      grid-row: 2/3;
      padding: 10px 0 15px 0;
      margin: 0;
      align-items: center;
      display: grid;
      grid-template: max-content / max-content max-content;
      justify-content: left;
    }
  }

  &__items {
    margin: 0;
    padding: 15px 0;
    list-style: none;
    display: grid;
    grid-template: max-content / 250px 1fr max-content;
    border-bottom: 1px solid #492e20;
    gap: 10px;
    pointer-events: auto;


    &_none {
      pointer-events: none;
    }

    @media screen and (min-width: 900px) and (max-width: 1260px) {
      grid-template: max-content / 250px 1fr max-content;
    }

    @media screen and (min-width: 200px) and (max-width: 899px) {
      grid-template: max-content max-content / 1fr;
      gap: 5px;
    }

  }

  &__itemsName {
    padding: 0;
    margin: 0;
    text-decoration: none;
    font-family: 'Cormorant Infant', 'Arial', sans-serif;
    font-size: 22px;
    font-weight: 700;
    color: global.$appMainColor;
    align-self: center;

    &_location {
      margin: 0 0 10px 0;
    }

  }

  &__checkArea {
    margin: 0 0 0 20px;
    padding: 0;
    align-items: center;
    display: grid;
    grid-template: max-content / max-content max-content;
    justify-content: left;

    @media screen and (min-width: 200px) and (max-width: 1350px) {
      grid-template: max-content max-content / max-content;
      gap: 5px;
    }

    @media screen and (min-width: 200px) and (max-width: 1260px) {
      grid-template: max-content / max-content max-content;
    }

  }

  &__buttonArea {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template: max-content / 150px 150px 1fr;
    gap: 20px;
  }

  &__button {
    margin: 20px 20px 0 0;
    padding: 10px;
    width: 100%;
    outline: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: global.$buttonEnabledColor;
    background-color: global.$backgroundColor;
    border: 1px solid global.$appMainColor;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 2px 2px 2px global.$appMainColor;

    &:hover {
      background-color: global.$appMainColor;
      color: global.$buttonTextColor;
      border: 1px solid global.$appMainColor;
    }

    &_save {
      grid-row: 1/2;
      grid-column: 1/2;
    }

    &_cancel {
      grid-row: 1/2;
      grid-column: 2/3;
    }

    &_disabled {
      display: none;
    }

  }

}
