@use "../../scss/mixin";
@use "../../scss/global";

.login {
   grid-row: 1/2;
   grid-column: 1/2;
   font-family: global.$mainFontFamily;
   box-sizing: border-box;
   padding: 0;
   margin: 0;
   display: grid;
   grid-template: 1fr / 1fr 1fr;

   @media screen and (min-width: 500px) and (max-width: 1023px) {
      grid-template: max-content 1fr/ 1fr;
   }

   @media screen and (max-width: 499px) {
      align-items: center;
      grid-template: 1fr / 1fr;
   }

   &__title {
      color: global.$appMainColor;
      margin: 0;
      padding: 0;
      font-weight: 600;
      font-size: 28px;
      line-height: 34px;
      text-align: center;
   }

   &__link {
      margin: 0;
      padding: 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      color: global.$textColorLink;

      &:visited {
         color: global.$textColorLink;

      }
   }

   &__block {
      grid-row: 1/2;
      grid-column: 1/2;
      margin: 0;
      padding: 0 150px;
      align-self: center;

      @media screen and (min-width: 1024px) and (max-width: 1920px) {
         padding-left: calc(150px - (30 - 150) * ((100vw - 1920px) / (1920 - 1024)));
         padding-right: calc(150px - (30 - 150) * ((100vw - 1920px) / (1920 - 1024)));
      }

      @media screen and (min-width: 500px) and (max-width: 1023px) {
         grid-row: 2/3;
         grid-column: 1/2;
         align-self: start;
         padding-left: calc(100px - (40 - 100) * ((100vw - 1023px) / (1023 - 500)));
         padding-right: calc(100px - (40 - 100) * ((100vw - 1023px) / (1023 - 500)));
      }

      @media screen and (min-width: 200px) and (max-width: 499px) {
         grid-row: 1/2;
         grid-column: 1/2;
         align-self: center;
         padding-top: 0;
         padding-left: calc(20px - (10 - 20) * ((100vw - 499px) / (499 - 200)));
         padding-right: calc(20px - (10 - 20) * ((100vw - 499px) / (499 - 200)));
      }

      @media screen and (max-height: 550px) {
         padding-top: 20px;
         padding-bottom: 20px;
      }

   }

   &__form {
      grid-row: 1/2;
      grid-column: 1/2;
      padding: 0;
      font-family: global.$navigateFontFamily;
      margin: auto;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 500px) and (max-width: 1023px) {
         grid-row: 2/3;
         grid-column: 1/2;
      }

      @media screen and (min-width: 200px) and (max-width: 499px) {
         padding-left: 20px;
         padding-right: 20px;
         justify-content: space-around;
      }
   }

   &__name {
      margin: 15px 0 5px;
      padding: 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      color: global.$mainColor;
   }

   &__input {
      color: global.$mainTextColor;
      background-color: global.$backgroundColor;
      font-family: global.$mainFontFamily;
      margin: 0;
      padding: 5px 5px 10px;
      border: none;
      outline: none;
      border-bottom: 1px solid global.$borderColor;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
   }

   &__inputError {
      font-family: global.$navigateFontFamily;
      margin: 7px 0 0;
      padding: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: global.$errorTextColor;
   }

   &__error {
      font-family: global.$navigateFontFamily;
      margin: 20px 0 0;
      padding: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: global.$errorTextColor;
   }

   &__submit {
      margin: 20px 0 0 0;
      padding: 10px;
      width: 100%;
      outline: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      background-color: global.$buttonEnabledColor;
      color: global.$buttonTextColor;
      border: 1px solid global.$borderColor;
      border-radius: 3px;
      cursor: pointer;

      &:hover {
         transition: all ease .5s;
      }

      &_disabled {
         background-color: global.$buttonDisabledColor;
         color: global.$borderColor;
         cursor: none;
      }
   }

   &__image {
      margin: 0;
      padding: 0;
      grid-row: 1/2;
      grid-column: 2/3;
      display: grid;
      grid-template:  1fr max-content max-content 1fr / 1fr;
      background-color: #d6d9dc;
      box-shadow: inset 0 0 25px rgba(214, 217, 220, 100), inset 0 0 25px rgb(104, 105, 107), inset 0 0 25px rgb(150, 154, 161), inset 0 0 25px rgb(125, 126, 128);

      @media screen and (min-width: 500px) and (max-width: 1023px) {
         padding-top: 40px;
         padding-bottom: 40px;
         padding-left: calc(100px - (30 - 100) * ((100vw - 1023px) / (1023 - 500)));
         padding-right: calc(100px - (30 - 100) * ((100vw - 1023px) / (1023 - 500)));
         grid-template: 1fr / max-content max-content;
         grid-row: 1/2;
         grid-column: 1/2;
         height: auto;
         background-color: global.$backgroundColor;
         box-shadow: none;
         display: flex;
         flex-direction: row;
         justify-content: center;
      }

      @media screen and (max-width: 499px) {
         display: none;
      }

      @media screen and (max-height: 550px) {
         display: none;
      }

   }

   &__topImage {
      margin: 0;
      padding: 0;
      grid-row: 2/3;
      justify-self: center;
      height: 250px;
      align-items: center;


      @media screen and (min-width: 1024px) and (max-width: 1920px) {
         height: calc(250px - (200 - 250) * ((100vw - 1920px) / (1920 - 1024)));
      }

      @media screen and (min-width: 500px) and (max-width: 1023px) {
         height: calc(150px - (100 - 150) * ((100vw - 1023px) / (1023 - 500)));
      }

      &_phon {
         display: none;
      }

   }

   &__bottomImage {
      margin: 0;
      padding: 0;
      grid-row: 3/4;
      height: 250px;
      justify-self: center;

      @media screen and (min-width: 1024px) and (max-width: 1920px) {
         height: calc(250px - (200 - 250) * ((100vw - 1920px) / (1920 - 1024)));
      }

      @media screen and (min-width: 500px) and (max-width: 1023px) {
         width: calc(350px - (250 - 350) * ((100vw - 1023px) / (1023 - 500)));
         height: calc(150px - (100 - 150) * ((100vw - 1023px) / (1023 - 500)));
      }

      &_phon {
         display: none;
      }
   }


}


