@use '../../scss/mixin';
@use '../../scss/global';

.sidebar {
   padding: 0;
   margin: 0;
   border-top: solid 2px global.$backgroundColor;

   &__navigate {
      padding: 0;
      margin: 0;
      font-size: 20px;
      display: grid;
      grid-template: repeat(6, max-content)/ 1fr;
      list-style: none;
   }

}

