@use "../../../scss/global";
@use "../../../scss/mixin";

.container {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   padding: 0;
   box-sizing: border-box;
   z-index: 1000;
}

.overlay {
   position: fixed;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: rgba(0, 0, 0, .7);
   z-index: 2;

   &_transparent {
      background-color: rgba(0, 0, 0, 0);
   }
}

.nonactive {
   display: none;
}
