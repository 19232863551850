@use '../../scss/global';
@use '../../scss/mixin';

.bread {
   @include mixin.breadCrumbs;

   &__marker {
      display: none;
   }

   &__menu {
      cursor: pointer;
      pointer-events: auto;
   }

}

