@use "../../../scss/global";
@use "../../../scss/mixin";

.messages {
  margin: 0 auto;
  padding: 20px;
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  border: 2px solid global.$appMainColor;
  border-radius: 8px;
  overflow: hidden;
  background-color: global.$backgroundPopupColor;
  z-index: 2000;

  &__text {
    grid-row: 2/3;
    grid-column: 2/3;
    margin: 0;
    padding: 0;
    text-align: center;
    @include mixin.textDialogueMessage;
  }

}