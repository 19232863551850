@use "../../scss/mixin";

.films {
  margin: 0;
  padding: 0 20px;
  display: grid;
  grid-template: max-content 1fr / 1fr;
}

.filmsList {
  margin: 0;
  padding: 20px 0;
  display: grid;
  grid-template: auto / 1fr;
  gap: 40px;
  list-style: none;

  @media screen and (min-width: 200px) and (max-width: 1920px) {
    gap: calc(10px + (40 - 16) * ((100vw - 200px) / (1920 - 200)));
  }

  &__button {
    margin: 0;
    padding: 0;
  }

  &__titleHome {
    @include mixin.titleUserPageInfo;

    @media screen and (min-width: 200px) and (max-width: 1260px) {
      text-align: center;
    }
  }

  &__item {
    margin: 0;
    padding: 0 5%;

    @media screen and (min-width: 1024px) and (max-width: 1920px) {
    }

    @media screen and (min-width: 200px) and (max-width: 1023px) {
    }
  }


  &__imageContainer {
    background-size: cover;
    padding: 0;
    margin: 0;
    height: auto;
    width: 100%;
    position: relative;
    text-align: center;
    color: white;
    overflow: hidden;
    cursor: pointer;
  }

  &__image {
    display: block;
    padding: 0;
    margin: 0;
    width: 100%;
    height: auto;
    object-fit: cover;

    @media screen and (max-width: 699px) {
      display: none;
    }

  }

  &__imageMin {
    display: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: auto;
    object-fit: cover;

    @media screen and (max-width: 699px) {
      display: block;
    }
  }

  &__imageTitle {
    margin: 0;
    padding: 0;
    @include mixin.titleImage;
    position: absolute;
    top: 10%;
    left: 5%;
    width: 70%;
    text-align: right;
  }

  &__textOverlay {
    visibility: hidden;
    position: absolute;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .8;
    z-index: 10;
    transition: ease visibility .3s;
  }

  &__textOverlay1 {
    display: block;
    position: absolute;
    margin: 0;
    padding: 0;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0;
    z-index: 30;
  }
  &__textImage {
    display: block;
    position: absolute;
    margin: 0;
    padding: 0;
    top: -100%;
    width: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: ease top 1s;
    z-index: 20;
    @include mixin.textImage;

    @media screen and (min-width: 700px) and (max-width: 1920px) {
      width: calc(90% + (70 - 90) * ((100vw - 700px) / (1920 - 700)));
    }

    @media screen and (min-width: 200px) and (max-width: 699px) {
      width: calc(90% + (70 - 90) * ((100vw - 200px) / (699 - 200)));
    }

  }

  &__imageContainer:hover &__textOverlay {
    visibility: visible;
  }

  &__imageContainer:hover &__textImage {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}