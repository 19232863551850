@use '../../../scss/global';
@use '../../../scss/mixin';

.field {
   margin: 0;
   padding: 0;
   display: grid;
   grid-template: max-content / max-content 3fr;
   gap: 15px;

   @media screen and (min-width: 200px) and (max-width: 600px) {
      grid-template: max-content max-content / 1fr;
      gap: 0;
   }

   &__name {
      grid-row: 1/2;
      grid-column: 1/2;
      padding: 0;
      margin: 0;
      @include mixin.titleCardInfo;
      align-self: center;

      @media screen and (min-width: 200px) and (max-width: 600px) {
         grid-row: 1/2;
         grid-column: 1/2;
      }
   }

   &__text {
      grid-row: 1/2;
      grid-column: 2/3;
      padding: 0;
      margin: 0 6px 0 0;
      align-self: center;
      @include mixin.textCardInfo;

      @media screen and (min-width: 200px) and (max-width: 600px) {
         grid-row: 2/3;
         grid-column: 1/2;
      }

   }

   &__array {
      list-style: none;
      grid-row: 1/2;
      grid-column: 2/3;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @media screen and (min-width: 200px) and (max-width: 600px) {
         grid-row: 2/3;
         grid-column: 1/2;
      }
   }

}


