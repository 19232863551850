@use "../../scss/mixin";
@use "../../scss/global";

.block {
   padding: 50px 20px 20px 50px;
   margin: 0;
   display: grid;
   grid-template: max-content / max-content 1fr;
   gap: 20px;

   &__image {
      grid-column: 1/2;
      padding: 0;
      margin: 0;
      width: 200px;
      height: 200px;
      border-radius: 50%;
   }

   &__title {
      grid-row: 1/2;
      grid-column: 2/3;
      @include mixin.titleUserPageInfo;
      text-align: center;
      align-self: center;
   }

}

.buttonArea {
   grid-row: 2/3;
   grid-column: 1/3;
   margin: 0;
   padding: 0;
   display: grid;
   grid-template: max-content / 1fr 3fr 1fr;
   gap: 20px;

   &__button {
      grid-column: 2/3;
      margin: 20px 20px 0 0;
      padding: 10px;
      width: 100%;
      outline: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      color: global.$buttonEnabledColor;
      background-color: global.$backgroundColor;
      border: 1px solid global.$appMainColor;
      border-radius: 3px;
      cursor: pointer;
      box-shadow: 2px 2px 2px global.$appMainColor;

      &:hover {
         background-color: global.$appMainColor;
         color: global.$buttonTextColor;
         border: 1px solid global.$appMainColor;
      }
   }
}



