@use '../../../scss/mixin';

.cardInfoDescription {
   margin: 0;
   padding: 0;

   &__list {
      padding: 0;
      margin: 0 0 10px 0;
      list-style: none;
      text-decoration: none;
   }

   &__text {
      padding: 0;
      margin: 0;
      text-align: justify;
      text-indent: 30px;
      @include mixin.textDescriptionCardInfo;
   }

   &__title {
      padding: 0;
      margin: 0;
      text-align: left;
      @include mixin.titleDescriptionCardInfo;
   }

}


