@use '../../scss/global';
@use '../../scss/mixin';

.card {
   @include mixin.card;

   &__button {
      margin: 5px 0 10px 0;
      padding: 5px;
      width: 100%;
      @include mixin.button;

      &:hover {
         background-color: global.$appMainColor;
         color: global.$backgroundCardColor;
      }
   }

}

.content {
   background-color: global.$backgroundCardColor;
   height: 100%;
   box-sizing: border-box;
   padding: 15px;
   margin: 0;
   display: grid;
   grid-template: auto / max-content 1fr;
   border: solid 1px global.$appMainColor;
   border-radius: 3px;
   cursor: pointer;
   visibility: visible;
   transition: transform 1s cubic-bezier(0.4, 0.2, 0.2, 1);
   backface-visibility: hidden;
   box-shadow: 5px 5px 10px rgba(0, 0, 0, .5);

   @media screen and (min-width: 200px) and (max-width: 399px) {
      grid-template: max-content 1fr /1fr;
      padding: 15px 5px 5px 5px;
   }


   &__infoContent {
      list-style: none;
      margin:0;
      padding: 0 0 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      @media screen and (min-width: 200px) and (max-width: 399px) {
         grid-row: 2/3;
         grid-column: 1/2;
      }
   }

   &__infoText {
      margin:0 0 0 10px;
      padding: 0;
      display: grid;
      grid-template: max-content / 2fr 6fr;
      gap: 10px;
   }

   &__image {
      grid-row: 1/2;
      grid-column: 1/2;
      padding: 0;
      margin: 0;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      align-self: center;

      @media screen and (min-width: 200px) and (max-width: 399px) {
         margin: 0 auto;
      }
   }

   &__info {
      grid-row: 1/2;
      grid-column: 2/3;
      padding: 10px;
      margin: 0;
   }

   &__title {
      grid-row: 1/2;
      grid-column: 1/2;

      padding: 0;
      margin: 0;
      @include mixin.titleCard;
   }

   &__text {
      grid-row: 1/2;
      grid-column: 2/3;
      padding: 0 0 5px 0;
      margin: 0;
      @include mixin.textCard;
      text-align: left;
   }

   &__brief {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      width: 100%;
      display: grid;
      grid-template: 5fr 2fr / 1fr;
      margin: 0;
      padding: 10px;
      border: solid 1px #000000;
      transition: transform 1s cubic-bezier(0.4, 0.2, 0.2, 1);
      backface-visibility: hidden;
      cursor: default;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, .5);
   }

   &__textBrief {
      grid-row: 1/1;
      padding: 0;
      margin: 0;
      @include mixin.textCardBrief;
      text-align: justify;
   }

   &__link {
      list-style: none;
      text-decoration: none;
      padding: 0 0 0 10px;
      margin: 0;
      grid-row: 2/2;
      position: absolute;
      color: global.$textColorLink;
      font-family: global.$mainFontFamily;
      font-size: 20px;
      font-weight: normal;
      display: inline;
      cursor: pointer;
   }

   &__linkSpan {
      display: inline;
      align-self: flex-start;
      cursor: pointer;
   }

   &:hover .content__brief,
   &:hover .content {
      transition: transform 1s cubic-bezier(0.4, 0.2, 0.2, 1);
   }

}

.card .content__brief {
   transform: rotateY(180deg);
   transform-style: preserve-3d;
   background-color: #e7e1db;
}

.card .content {
   transform: rotateY(0deg);
   transform-style: preserve-3d;
}

.card:hover .content__brief {
   transform: rotateY(0deg);
   transform-style: preserve-3d;
   background-color: #e7e1db;
}

.card:hover .content {
   transform: rotateY(-180deg);
   transform-style: preserve-3d;
}
