@use "../../scss/mixin";
@use "../../scss/global";

.personInfo {
   grid-row: 2/3;
   grid-column: 1/2;
   padding: 15px 20px 20px 20px;
   color: global.$mainTextColor;
   font-family: global.$mainFontFamily;
   font-weight: 400;
   font-size: 20px;

   &_crambsBlock {
      margin: 0;
      padding: 0;
      display: grid;
      grid-template: max-content / 1fr max-content;
   }

   &_crambs {
      margin: 0;
      padding: 0;
      grid-row: 1/2;
      grid-column: 1/2;
   }

   &__imgButton {
      display: block;
      box-sizing: border-box;
      height: 35px;
      margin: 0 0 0 5px;
      padding: 5px;
      background-color: global.$appMainColor;
      border-radius: 3px;
      align-items: center;
      cursor: pointer;
      transition: opacity 0.2s ease;

      &_none {
         display: none;
      }
   }

   &__mainInfo {
      grid-row: 1/2;
      display: grid;
      grid-template: max-content / max-content 1fr;
      padding: 0;
      margin: 10px 0 0 0;
      gap: 50px;

      @media screen and (min-width: 800px) and (max-width: 1920px) {
         gap: calc(20px + (50 - 20) * ((100vw - 800px) / (1920 - 800)));
      }

      @media screen and (min-width: 200px) and (max-width: 799px) {
         gap: calc(5px + (15 - 5) * ((100vw - 200px) / (799 - 200)));
      }

      @media screen and (min-width: 200px) and (max-width: 799px) {
         grid-template: max-content max-content / 1fr;
      }

   }

   &__image {
      grid-row: 1/2;
      grid-column: 1/2;
      padding: 0;
      margin: 10px 0 0 0;
      width: 250px;
      height: auto;
      border-radius: 50%;
      align-self: start;
      justify-self: center;

      @media screen and (min-width: 200px) and (max-width: 1920px) {
         width: calc(180px + (250 - 180) * ((100vw - 200px) / (1920 - 200)));
      }

      @media screen and (min-width: 200px) and (max-width: 799px) {
         grid-row: 1/2;
         grid-column: 1/2;
         align-self: center;

      }
   }

   &__content {
      grid-row: 1/2;
      grid-column: 2/3;
      display: grid;
      grid-template: max-content / 1fr;
      align-self: flex-start;
      gap: 5px;
      margin: 0;
      padding: 0;

      @media screen and (min-width: 200px) and (max-width: 799px) {
         grid-row: 2/3;
         grid-column: 1/2;
         gap: 10px;
      }

   }

   &__description {
      grid-row: 2/3;
      grid-column: 1/2;
      margin: 20px 0 0 0;
      padding: 0;
      @include mixin.textCardInfo;
   }

   &_arrayItem {
      margin: 0;
      padding: 0;
      list-style: none;
   }

}