@use "../../scss/global";
@use "../../scss/mixin";

.checkboxArea {
   margin: 0;
   padding: 0;
}

.checkbox {
   position: absolute;
   z-index: -1;
   opacity: 0;
   margin: 10px 0 0 20px;

   & + label {
      position: relative;
      padding: 0 0 0 60px;
      cursor: pointer;
   }

   & + label:before {
      content: '';
      position: absolute;
      top: -4px;
      left: 0;
      width: 50px;
      height: 26px;
      border-radius: 13px;
      background: #CDD1DA;
      box-shadow: inset 0 2px 3px rgba(0, 0, 0, .2);
      transition: .2s;
   }

   & + label:after {
      content: '';
      position: absolute;
      top: -2px;
      left: 2px;
      width: 22px;
      height: 22px;
      border-radius: 10px;
      background: global.$backgroundColor;
      box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
      transition: .2s;
   }

   &:checked + label:before {
      background: global.$appMainColor;
   }

   &:checked + label:after {
      left: 26px;
   }

}
