@use '../../../scss/global';
@use '../../../scss/mixin';

.fieldLinks {
   margin: 0;
   padding: 0;
   display: grid;
   grid-template: max-content / max-content max-content 3fr;
   gap: 5px;

   @media screen and (min-width: 200px) and (max-width: 600px) {
      grid-template: max-content max-content / 1fr;
      gap: 0;
   }

   &_short {
      gap: 2px;
   }

   &__name {
      grid-row: 1/2;
      grid-column: 1/2;
      padding: 0;
      margin: 0;
      @include mixin.titleCardInfo;
      align-self: start;

      @media screen and (min-width: 200px) and (max-width: 600px) {
         grid-row: 1/2;
         grid-column: 1/2;
      }

   }

   &__text {
      grid-row: 1/2;
      grid-column: 2/3;
      padding: 0;
      margin: 0 6px 0 0;
      align-self: center;
      @include mixin.textCardInfo;

      @media screen and (min-width: 200px) and (max-width: 600px) {
         grid-row: 2/3;
         grid-column: 1/2;
      }
   }

   &__text_clickable {
      text-decoration: none;
      align-self: center;
      @include mixin.titleCardInfoLink;
      cursor: pointer;
   }

   &__arrayWrapper {
      grid-row: 1/2;
      grid-column: 2/3;
      padding: 0;
      margin: 0;
      display: grid;
      grid-template: auto / 1fr max-content;
      column-gap: 5px;
   }

   &__array {
      list-style: none;
      grid-row: 1/2;
      grid-column: 3/4;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      //justify-content: space-between;
      flex-wrap: wrap;

      @media screen and (min-width: 200px) and (max-width: 600px) {
         grid-row: 2/3;
         grid-column: 1/2;
      }
   }

   &_array_markArrow {
      grid-row: 1/2;
      grid-column: 2/3;
      display: inline-block;
      padding: 10px 0 0 0;
      margin: 0;
      width: 20px;
      height: 20px;
      cursor: pointer;
      align-self: start;
   }

   &_array_markColon {
      grid-row: 1/2;
      grid-column: 2/3;
      //display: inline-block;
      //padding: 10px 0 0 0;
      margin: 0;
      //width: 20px;
      //height: 20px;
      //cursor: pointer;
      align-self: start;
   }

   &__text_none {
      display: none;
   }

   &__continue {
      margin: 0;
      padding: 0;
      @include mixin.titleCardInfoLink;
   }


}


