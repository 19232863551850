@use "../../../scss/mixin";
@use "../../../scss/global";

.personInfo {
   grid-row: 2/3;
   grid-column: 1/2;
   padding: 20px 20px 20px 50px;
   color: global.$mainTextColor;
   font-family: global.$mainFontFamily;
   font-weight: 400;
   font-size: 20px;

   &__mainInfo {
      grid-row: 1/2;
      display: grid;
      grid-template: max-content / 250px 1fr;
      padding: 0;
      margin: 10px 0 0 0;
      gap: 50px;
   }

   &__blockAvatar {
      grid-row: 1/2;
      grid-column: 1/2;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
   }

   &__imageAvatar {
      padding: 0;
      margin: 0;
      width: 250px;
      height: auto;
      border-radius: 50%;

      @media screen and (min-width: 200px) and (max-width: 1920px) {
         width: calc(180px + (250 - 180) * ((100vw - 200px) / (1920 - 200)));
      }

      @media screen and (min-width: 200px) and (max-width: 1260px) {
         margin: 0 auto;
      }
   }

   &__linkChangeAvatar {
      padding: 0;
      margin: 20px 0 0 0;
      text-decoration: none;
      font-family: 'Cormorant Infant', 'Arial', sans-serif;
      font-size: 22px;
      font-weight: 700;
      color: global.$appMainColor;
      cursor: pointer;

      @media screen and (min-width: 200px) and (max-width: 1260px) {
         margin: 0 auto;
         padding: 10px 0;
      }

   }

   &__triangleRight {
      display: inline-block;
      margin: 0;
      padding: 0;
      font-size: 14px;
      align-items: center;
   }

   &__triangleDown {
      display: inline-block;
      margin: 0;
      padding: 0;
      font-size: 20px;
      align-items: center;
   }

   &__triangleNone {
      display: none;
   }

   &__changeAvatar {
      padding: 0;
      margin: 20px 0 0 0;
      text-align: center
   }

}

.errorAvatar {
   margin: 0;
   padding: 0 0 10px 0;
   display: block;

   &__hide {
      display: none;
   }

   &__errorText {
      margin: 10px 0 0 0;
      padding: 0;
      color: global.$errorTextColor;
      font-size: 16px;
      text-align: left;
   }
}

.change {
   margin: 10px 0 0 0;
   padding: 0;
   display: grid;
   grid-template: max-content / 1fr max-content;
   gap: 10px;

   &_hide {
      display: none;
   }

   &__uploadImg {
      grid-row: 1/2;
      grid-column: 2/3;
      width: 25px;
      margin: 0;
      padding: 5px;
      background-color: global.$appMainColor;
      pointer-events: none;
      opacity: .2;

      &_active {
         pointer-events: auto;
         cursor: pointer;
         opacity: 1;
      }
   }

   &__uploadText {
      grid-column: 1/3;
      margin: 10px 0 0 0;
      padding: 0;
      color: global.$appMainColor;
      font-size: 16px;
      text-align: justify;
   }

}

.inputBlock {
   grid-row: 1/2;
   grid-column: 1/2;
   margin: 0;
   padding: 0;
   background-color: global.$backgroundColor;
   border: 1px solid global.$appMainColor;
   display: grid;
   grid-template: max-content / max-content 1fr;
   gap: 10px;
   cursor: pointer;

   &__input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      display: block;
      width: 0;
      height: 0;
   }

   &__img {
      grid-row: 1/2;
      grid-column: 1/2;
      width: 25px;
      margin: 0;
      padding: 5px;
      background-color: global.$appMainColor;
   }

   &__text {
      grid-row: 1/2;
      grid-column: 2/3;
      margin: 0;
      padding: 0;
      align-self: center;
      background-color: global.$backgroundColor;
      color: global.$appMainColor;
      font-size: 16px;
   }
}


