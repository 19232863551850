
@font-face {
    font-family: 'Cormorant Infant';
    src: local('Cormorant Infant Bold'), local('CormorantInfant-Bold'),
        url('CormorantInfant-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}


@font-face {
    font-family: 'Cormorant Infant';
    src: local('Cormorant Infant Medium'), local('CormorantInfant-Medium'),
        url('CormorantInfant-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Cormorant Infant';
    src: local('Cormorant Infant Regular'), local('CormorantInfant-Regular'),
        url('CormorantInfant-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Cormorant Infant';
    src: local('Cormorant Infant Light'), local('CormorantInfant-Light'),
        url('CormorantInfant-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Cormorant Infant';
    src: local('Cormorant Infant SemiBold'), local('CormorantInfant-SemiBold'),
        url('CormorantInfant-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Caviar Dreams';
    src: local('Caviar Dreams'), local('CaviarDreams'),
    url('CaviarDreams.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Caviar Dreams';
    src: local('Caviar Dreams Bold'), local('CaviarDreams-Bold'),
    url('CaviarDreams-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Verona';
    src: local('Verona Cyrillic'), local('Verona_cyr'),
    url('Verona_cyr.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Verona';
    src: local('Verona Cyrillic Bold'), local('Verona_cyr-Bold'),
    url('Verona_cyr-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}