@use "../../../scss/global";
@use "../../../scss/mixin";

.menu {
   margin: 0;
   padding: 10px 15px;
   position: fixed;
   top: 38px;
   box-sizing: border-box;
   border: 1px solid global.$borderColor;
   box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
   border-radius: 8px;
   overflow: hidden;
   background-color: global.$backgroundPopupColor;
   z-index: 200;
   min-width: 200px;
   min-height: 200px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;

   &__line {
      margin: 0;
      padding: 0;
      width: 100%;
   }

   &__text {
      margin: 0;
      padding: 0;
      font-size: 16px;
      font-weight: 400;
      color: global.$headerColor;
   }

   &__text_bold {
      font-weight: 600;
   }

   &__text_pointer {
      cursor: pointer;
   }

   &__noVisible {
      display: none;
   }
}