@use "../../scss/global";
@use "../../scss/mixin";

.home {
  margin: 0;
  padding: 40px 0;
  display: grid;
  grid-template: auto / 1fr;
  gap: 40px;
  list-style: none;

  &__button {
    margin: 0;
    padding: 0;
  }

  &__titleHome {
    @include mixin.titleUserPageInfo;

    @media screen and (min-width: 200px) and (max-width: 1260px) {
      text-align: center;
    }
  }

}