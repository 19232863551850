@use "../../scss/global";
@use "../../scss/mixin";

.home {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
   grid-row: 1/2;
   display: grid;
   grid-template: 1fr/ 179px 1fr;

   @media screen and (max-width: 1023px) {
      grid-template: 1fr/ 1fr;
   }

   &__title {
      @include mixin.menuPageLink;
   }

   &__sidebar {
      position: fixed;
      top: global.$headerHeight;
      left: 0;
      width: 179px;
      margin: 0;
      padding: 0;
      transition: top 0.3s; /* Эффект перехода при скольжении вниз (и вверх) */
      border-right: 1px solid global.$borderColor;
      display: block;

      @media screen and (max-width: 1023px) {
         display: none;
      }

   }

   &__main {
      margin: 0;
      padding: 0;
      border-left: solid 1px global.$borderColor;
      grid-row: 1/2;
      grid-column: 2/3;

      @media screen and (max-width: 1023px) {
         grid-column: 1/2;
         border-left: none;
         padding: 10px;
      }

      @media screen and (min-width: 700px) and (max-width: 769px) {
         padding: 0;
      }

      @media screen and (min-width: 200px) and (max-width: 399px) {
         padding: 5px;
      }
   }

   &__crumbs {
      margin: 0;
      padding: 0;
      grid-row: 1/2;
      grid-column: 1/2;
   }

   &__content {
      margin: 0;
      padding: 0;
      grid-row: 2/3;
      grid-column: 1/2;
   }

}