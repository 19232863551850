@use '../../../scss/global';
@use '../../../scss/mixin';

.active {
  @include mixin.menuText;
  color: global.$backgroundColor;
  text-decoration: none;
  background-color: global.$textColorMenuSidebar;
}

.link {
  @include mixin.menuText;
  text-decoration: none;
}

.activeLink {
  display: block;
  padding: 10px 0 10px 10px;
  background-color: global.$textColorMenuSidebar;
  @include mixin.menuText;
  color: global.$backgroundColor;
  text-decoration: none;
  width: 169px;
}

.nonactiveLink {
  display: block;
  padding: 10px 0 10px 10px;
  background-color: global.$backgroundColor;;
  @include mixin.menuText;
  text-decoration: none;
  width: 169px;
}